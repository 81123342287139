/*******************************
         Site Overrides
*******************************/
.ui.form .field > label {
    font-weight: normal!important;
}

.ui.form .inline.fields > label {
    font-weight: normal!important;
}

.ui.form .aligned .checkbox input {
    position: relative;
}

.small .ui.form .field {
    margin: 0;
}

.small .ui.selection.dropdown {
    min-width: 12em;
}