/*******************************
         Site Overrides
*******************************/
:root {
    --pushable-width: @width;
}

.pusher.pushable {
    &.open {
        // padding-left: @width!important;
    }
}

.ui.sidebar {
    padding-left: 16px;
    padding-right: 16px;
}

.ui.sidebar.menu .item {
    border-radius: 12px !important;
}

.ui.sidebar.menu .item.active-group {
    background: @spcsVanilla05;
}

.ui.menu .content .item {
    padding-left: 16px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
}