/*******************************
         Site Overrides
*******************************/
.ui.menu {
  font-size: @big;
}

.ui.vertical.menu .item:before {
  background: transparent;
}

.ui.pagination.menu .item, .ui.pagination.menu .item.active {
  padding: 10px 20px
} 

.ui.tabular.menu .item, .ui.pointing.menu .item {
  padding: 10px 20px
}

.ui.tabular.menu .active.item {
  font-weight: normal;
}

.ui.dropdown .menu > .header {
  font-weight: normal!important;
  font-size: @large!important;
}

.ui.dropdown .menu > .item {
  font-size: @medium;
}

.ui.pointing.menu .item.active:after {
  background-color: @spcsPurple70;
}


.ui.item.dropdown .dropdown.icon:before {
  font-family: Icons;
  content: '\f078';
}

.ui.item.dropdown.circled .dropdown.icon:before {
  background: @neutral05!important;
  color: @spcsPurple70;
  border-radius: 12px;
  padding: 11px 12px;
}

.ui.attached.tabular.menu {
  overflow-x: auto;
  overflow-y: hidden;
}

.ui.popup .ui.menu .item {
  padding: 15px;
}

@media only screen and (max-width: 767px) {
  div .ui.items:not(.unstackable) > .item > .image, .ui.items:not(.unstackable) > .item > .image > img {
    width: 80px!important;
    margin-left: 0;
    margin-right: 0;
  }
}