/*******************************
    User Variable Overrides
*******************************/
:root {
    --border-radius: @borderRadius;
}
.ui.cards > .card.modern > :first-child, .ui.card > .imageWrapper:first-child {
    padding: 12px
}

.ui.cards > .card.modern > :first-child, .ui.card > .imageWrapper:first-child img {
    border-radius: var(--border-radius)!important;
}

.ui.card.modern .header {
    padding: 12px;
    text-align: center;
}

.ui.cards > .card.modern > .content, .ui.card > .content {
    border-top: 0px solid transparent;
    text-align: center;
}
