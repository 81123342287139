/*******************************
         Site Overrides
*******************************/
.ui.attached.table:not(.left) {
    margin-left: 0;
}

.ui.attached.menu:not(.tabular):not(.left) {
    margin-left: 0;
}

.ui.table thead th {
    font-weight: normal;
}