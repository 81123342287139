/*******************************
         Site Overrides
*******************************/
:root {
    --spcs-purple-70: @spcsPurple70;
    --spcs-coral-60: @spcscoral60;
    --neutral-30: @neutral30;
    --neutral-05: @neutral05;
    --orange-60: @orange60;
    --spcs-vanilla-05: @spcsVanilla05;
    --green-30: @green30;
    --green-60: @green60;
    --green-70: @green70;
    --blue-50: @blue50;
    --red-70: @red70;
    --neutral-60: @neutral60;
    --neutral-80: @neutral80;
    --blue-10: @blue10;
    --red-05: @red05;
    --red-60: @red60;

    --positive-background-color: @positiveBackgroundColor;
    --positive-border-color: @positiveBorderColor;
    --positive-header-color: @positiveHeaderColor;
    --positive-text-color: @positiveTextColor;
    --text-color: @textColor;
    --line-height: @lineHeight;

    --mini: @mini;
    --tiny: @tiny;
    --small: @small;
    --medium: @medium;
    --large: @large;
    --big: @big;
    --huge: @huge;
    --massive: @massive;
}

h1, h2, h3, h4, h5 {
    font-weight: normal;
}

strong, b {
    font-weight: normal;
}

.show-strong strong, .show-strong b {
    font-weight: bold;
}