/*******************************
        User Overrides
*******************************/
.ui.accordion .title .dropdown.icon,
.ui.accordion .accordion .title .dropdown.icon {
  font-family: Icons;
  font-size: 12px;
}

.menu .ui.accordion .title .dropdown.icon:before,
.menu .ui.accordion .accordion .title .dropdown.icon:before {
  content: '\f077';
}

.ui.accordion.menu .item .title > .dropdown.icon {
  margin-right: 25px;
}

.ui.accordion.menu .item {
  padding-right: 8px;
  padding-left: 16px;
  margin-bottom: 2px;
}
