/*******************************
        User Overrides
*******************************/
.ui.dropdown.normal  {
        border: 1px solid @borderColor;
        padding: @inputPadding;
        border-radius: @absoluteBorderRadius;       
}

.ui.dropdown.normal.active {
        border: 1px solid @green60;
}

.ui.dropdown.flex a.ui.label {
        max-width: 200px;
        align-items: center;
        display: flex;
        justify-content: space-between;
}