/*******************************
         Site Overrides
*******************************/
.ui.button {
    font-weight: normal;
    i {
        display: inline-block;
        vertical-align: middle;
    }
}
