/*******************************
         Site Overrides
*******************************/

.ui.checkbox.circle input:checked ~ .box:before, .ui.checkbox input ~ label:before {
    border-radius: 50%;
    width: 19px;
    height: 19px;
}

.ui.checkbox.circle .box:after, .ui.checkbox label:after {
    font-size: small;
    top: 1px;
    left: 1px;
}

.ui.checkbox.green input:checked ~ label:before {
    border-color: var(--green-60);
    background: var(--green-60);
}

.ui.checkbox.green input ~ label:after, .ui.checkbox.green input:checked ~ label:after {
    color: var(--white);
}